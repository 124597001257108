@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.error {
  padding: 5px;
  background-color: var(--marketplaceColor);
  color: white;
  padding-left: 20px;
  margin-top: 20px;
}
.error a {
  color: white;
  text-decoration: underline;
}

h2.header {
  font-weight: var(--fontWeightBold);
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
}
h3.header {
  font-weight: var(--fontWeightBold);
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.5px;
  margin-top: 0px;
  clear: both;
  display: block;
  width: 100%;
  margin-bottom: 0px;
}

div.invitedBy {
  font-weight: var(--fontWeightBold);
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.5px;
  margin-top: 20px;
  clear: both;
  display: block;
  width: 100%;
  margin-bottom: 0px;
}
div.invitedBy ul {
  padding: 0px;
  margin: 0px;
}

.hiddenFields {
  visibility: hidden;
  height: 1px;
}

div.invitedBy li span {
  text-transform: capitalize;
}
div.invitedBy li .email {
  text-transform: lowercase;
}

div.formContainer {
  padding-top: 0px;
}
div.formContainer label {
  display: block;
}

textarea {
  height: 90px;
  resize: vertical;
}
div.formContainer h3 {
  margin-bottom: 0px;
}
.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
